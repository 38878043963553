<template>
  <div>
    <h1 class="h3 mb-4">
      {{ $t('product.product_category') }}
    </h1>
    <a-row :gutter="24" class="mt-4 mb-4">
      <a-col :span="12">
        <a-input-search
          v-model="params.criteria"
          size="large"
          :placeholder="$t('utils.search') + `${' '}` + $t('product.product_category')"
          :loading="isLoading"
          :disabled="isLoading"
          allow-clear
          @search="onSearch"
        >
          <a-button slot="enterButton" class="bg-primary-blue text-white">
            {{ $t('utils.search') }} <a-icon type="search" />
          </a-button>
        </a-input-search>
      </a-col>
    </a-row>
    <a-table
      class="payment-list"
      :data-source="categories"
      :row-key="record => record.id"
      :columns="columns"
      :loading="isLoading"
      :pagination="true"
      :scroll="scroll"
    >
      <template slot="name" slot-scope="text, record">
        <div class="ml-5" style="width: 80%">
          <a-cascader
            :value="findParent([record.data], record.id)"
            style="width: 100%; color: black"
            popup-class-name="kategori-options"
            :options="categoryOptions(record.data)"
            placeholder=""
            disabled
          />
        </div>
      </template>
      <template slot="status" slot-scope="text, record">
        <a-tag :color="record.data?.status === 'ACTIVE' ? 'green' : 'red'">
          {{ record.data?.status }}
        </a-tag>
      </template>
      <div slot="expandedRowRender" slot-scope="record">
        <div v-if="record.default.length > 0" class="mb-3">
          <div class="fw-bold fs-14 mb-3">
            Kategori Product Default
          </div>
          <div v-for="item, index in record.categori_default_id" :key="index">
            <div class="d-flex">
              <div style="width: 10%">
                PowerBiz
              </div>
              <div class="ml-5" style="width: 80%">
                <a-cascader
                  :value="findParent([record.default[index]], item)"
                  style="width: 100%"
                  popup-class-name="kategori-options"
                  :options="categoryOptions(record.default[index])"
                  placeholder=""
                />
              </div>
            </div>
            <a-divider v-if="index !== record.categori_default_id.length - 1" />
          </div>
        </div>
        <div>
          <div class="fw-bold fs-14 mb-4">
            Kategori Product Channel
          </div>
          <div v-for="item, index in record.channel" :key="item.sales_channel_id">
            <div class="d-flex">
              <div style="width: 10%">
                {{ item.sales_channel_name }}
              </div>
              <div v-for="e, i in item.data" :key="i" style="width: 80%">
                <div v-if="[e]?.length > 0" class="ml-5" style="width: 100%">
                  <a-cascader
                    :value="findParent([e], e.category_channel_id)"
                    style="width: 100%; color: black"
                    popup-class-name="kategori-options"
                    :options="categoryOptions(e)"
                    placeholder=""
                    disabled
                  />
                </div>
              </div>
            </div>
            <a-divider v-if="index !== record.channel.length - 1" />
          </div>
        </div>
      </div>
    </a-table>
    <!-- <div class="mt-4 text-right">
      <a-pagination
        v-model="params.page"
        :page-size="params.limit"
        :page-size-options="sizeOptions"
        :total="totals"
        :show-total="
          (total, range) =>
            $t('utils.pagination_show_total', {
              perpage: `${range[0]} - ${range[1]}`,
              total,
            })
        "
        :disabled="isLoading || !payments.length"
        show-size-changer
        @change="onPageChange"
        @showSizeChange="onShowSizeChange"
      />
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'CategoryProduct',
}
</script>
<script setup>
import debounce from 'lodash/debounce'
import route from '@/router'
import {
  computed,
  getCurrentInstance,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue'
import { columnsCategory } from '@/data/columns'
// import { useCurrency } from '@/composable/useCurrency'
// import { useInputNumber } from '@/composable/useInputNumber'
// import { useDateTime } from '@/composable/useDateTime'
// import InputNumber from '@/components/Input/InputNumber.vue'
import { getCategoryList } from '@/api/product'

const { $i18n, $message, $notification, $route, $store } =
  getCurrentInstance().proxy.$root
// const { format } = useCurrency()
// const { toNumber } = useInputNumber()
// const { parseDate } = useDateTime()

// Data
const businessId = ref(
  Object.keys($store.getters['user/businessListObjectByKey'])[0],
)
const sellerId = ref('')
const totals = ref(0)
const screenHeight = ref(((screen.height - 900) * 0.5) + (screen.height * 0.5))
const nominal = ref(null)
const mistake = ref(undefined)
const isLoading = ref(false)
const visible = ref(false)
const visibleBill = ref(false)
const visibleImage = ref(false)
const visibleMistake = ref(false)
const detailProof = ref(false)
const columns = ref(columnsCategory)
const categories = ref([])
const sizeOptions = ref(['10', '20', '30', '40', '50'])
const options = ref([
  { label: 'Semua', value: 'WAITING,CONFIRMED,CANCELLED,REJECTED,OUTSTANDING' },
  { label: 'Perlu Konfirmasi', value: 'WAITING' },
  { label: 'Sudah di Konfirmasi', value: 'CONFIRMED' },
  { label: 'Dibatalkan', value: 'CANCELLED' },
  { label: 'Ditolak', value: 'REJECTED' },
])
const optionsMistake = ref([
  { label: 'Pembayaran sudah masuk, bukti salah', value: '1' },
  { label: 'Pembayaran belum masuk, bukti benar', value: '2' },
  { label: 'Pembayaran belum masuk, bukti salah', value: '3' },
])
const colors = reactive({
  CONFIRMED: 'blue',
  WAITING: 'orange',
  CANCELLED: 'red',
  REJECTED: 'red',
  OUTSTANDING: 'gray',
})
const params = reactive({
  criteria: '',
  status: 'WAITING,CONFIRMED,CANCELLED,REJECTED,OUTSTANDING',
  page: 1,
  limit: 10,
})
let proof = reactive({})
// eslint-disable-next-line no-unused-vars
let invoice = reactive({})

// Computed
const business = computed(
  () => Object.keys($store.getters['user/businessListObjectByKey'])[0],
)
const scroll = computed(() => ({
  y: screenHeight.value > 450 ? screenHeight.value : 450,
}))
const tHeight = computed(() => {
  return !categories?.value.length
    ? '0px'
    : screenHeight.value > 450
    ? `${screenHeight.value}px`
    : '450px'
})
const title = computed(() => {
  return detailProof.value
    ? $i18n.t('finance.transaction_detail')
    : $i18n.t('finance.confirm_transfer')
})

// Method
const fetchCategories = debounce(async () => {
  isLoading.value = true
  try {
    const { data } = await getCategoryList({
      path: '/grouped-v2',
      params: { business_id: route.app._route.query.business_id },
    })
    isLoading.value = false
    categories.value = data
  } catch(e) {
    console.log({e})
    isLoading.value = false
  }
}, 500)

const subRecurse = (subLists) => {
  let subParent = []
  subLists.forEach(subList => {
    let prop = 'child'
    let subChildren = []
    const { id, name } = subList
    if (subList.children) {
      subChildren = subRecurse(subList.children)
      if (subChildren.length) prop = 'children'
    }
    subParent.push({
      value: id,
      label: name,
      [prop]: subChildren,
    })
  })
  return subParent
}

const recurse = (lists) => {
  let parent = []
  lists.forEach(list => {
    let prop = 'child'
    let children = []
    const { id, name } = list
    if (list.children) {
      children = subRecurse(list.children)
      if (children.length) prop = 'children'
    }
    parent.push({
      value: id,
      label: name,
      [prop]: children,
    })
  })
  return parent
}

const categoryOptions = (param) => {
  return recurse([param])
}

const findChildren = (item, matched) => {
  // if (item.id === matched) {
  //   return [item.id];
  // } else 
  if (item.children && item.children.length > 0) {
    for (let i = 0; i < item.children.length; i++) {
      const result = findChildren(item.children[i], matched);
      if (result) {
        return [item.id, ...result];
      }
    }
  } else {
    return [item.id];
  }
  // return null;
}

const findParent = (array, matched) => {
  for (let i = 0; i < array.length; i++) {
    const result = findChildren(array[i], matched);
    if (result) {
      return result;
    }
  }
  return [];
}

const onSearch = () => {}

// Watch
watch(
  () => business.value,
  newValue => {
    businessId.value = newValue
    fetchCategories()
  },
)

// watch(
//   () => route.app._route,
//   newValue => {
//     if (newValue.name === $route.name && !!newValue.query.warehouse_id) {
//       fetchSeller()
//     }
//   },
// )

onMounted(fetchCategories)
</script>

<style lang="scss">
.fs-14 {
  font-size: 14px !important;
}
.fw-bold {
  font-weight: bold;
}
.pointer {
  cursor: pointer;
}
.payment-status {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 2;
}
.image-thumbnail {
  width: 30px;
  height: auto;
}
.image-default {
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}
.payment-list div.ant-table-body {
  min-height: v-bind(tHeight);
}
.ant-col-14 {
  font-weight: 500;
  color: #6c757d;
}
.cad-table {
  overflow-x: scroll;
}
.ant-table-row-expand-icon {
  color: #2196f3 !important;
  border-color: #2196f3 !important;
  line-height: 17px !important;
}
tr.ant-table-expanded-row {
  position: relative;
  background: #f4f4f4 !important;

  label {
    font-weight: 500;
  }
}
</style>
